import { BrowserRouter } from 'react-router-dom';

import AuthProvider from 'context/AuthProvider';
import Toaster from 'context/Toaster';
import AppRoutes from 'routes/AppRoutes';

function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
