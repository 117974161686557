import { ReactComponent as CircularProgress } from 'assets/images/circular-progress.svg';

function Loader() {
  return (
    <div
      data-testid="loader-wrapper"
      className="absolute inset-0 flex flex-col items-center justify-center bg-white"
    >
      <div
        data-testid="loader-inner"
        className="flex flex-col items-center justify-center gap-2"
      >
        <CircularProgress
          data-testid="circular-progress"
          className="animate-spin"
        />
        <p data-testid="loading-text" className="text-sm text-black">
          Cargando
        </p>
      </div>
    </div>
  );
}

export default Loader;
